import React from 'react';
import { subTitle } from './subTitle.module.scss';

export interface ISubTitleProps {
    Tag?: React.ElementType;
}

const SubTitle: React.FC<ISubTitleProps> = ({
    Tag = 'h3',
    children,
}) => {
    return (
        <Tag className={subTitle}>
            {children}
        </Tag>
    );
};

export default SubTitle;
