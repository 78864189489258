import React from "react";

import { container, titleText, descriptionText, barColor, paddingIntro } from './page-intro.module.scss';

import Title from "../atoms/title";
import SubTitle from "../atoms/subTitle";
import Markdown from "../hoc/markdown";

interface IPageIntroProps {
    className?: string;
    title?: string;
    subTitle?:string;
    description?: string;
    showLogo?: boolean;
    showBackground?: boolean;
}

const PageIntro: React.FC<IPageIntroProps> = ({
    className = '',
    title = '',
    description= '',
    subTitle,

    }) => {
    return (
        <div className={`${container} ${className} ${paddingIntro}`}>
            {title && (
                <Title className={titleText}>
                    {title.toUpperCase()}
                </Title>
            )}
            {subTitle &&
                <SubTitle>
                    {subTitle.toUpperCase()}
                </SubTitle>}
            <div className={barColor}></div>
            {description && (
                <Markdown className={descriptionText}>
                    {description}
                </Markdown>
            )}
        </div>
    );
};

export default PageIntro;