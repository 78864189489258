import React from "react";

import { container, content, titleWrapper, titleHeader, titleSubtitle, list, itemBox, square} from './square-list.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { ISquareItem } from "../../models/square-item.model";

import Title, { ITitleProps } from "../atoms/title";
import SquareItem from "../molecules/square-item";

export interface ISquareListProps {
    className?: string;
    title?: string;
    subtitle?:string;
    titleProps?: Omit<ITitleProps, 'className'>;
    items: ISquareItem[];
    isBoldReversed?: boolean;
    imgBig?:boolean;
}

const SquareList: React.FC<ISquareListProps> = ({
    className = '',
    title,
    subtitle,
    titleProps = {},
    items,
    isBoldReversed,
}) => {
    return (
        <div className={`${container} ${className} ${grid}`}>
            <div className={content}>
                {title && (
                    <Title className={titleWrapper} {...titleProps}>
                        <div className={titleHeader}>{title?.toUpperCase()}</div>
                        <div className={titleSubtitle}>{subtitle?.toUpperCase()}</div>
                    </Title>
                )}
                <ul className={list}>
                    {items.map((item, index) => {
                        return (
                            <li
                                className={itemBox}
                                key={`square-item-${index}`}
                            >
                                <SquareItem className={square} item={item} isBoldReversed={isBoldReversed} imgBig/>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default SquareList;