import slugify from 'slugify';

import { ISquareItem } from '../models/square-item.model';
import { IProductPreview } from '../models/product-preview.model';
import { getSlugBySite } from './get-slug-by-site';

export function getSquareItemsFromRecipes(recipes: IProductPreview[]): ISquareItem[] {
    return recipes.map((recipe) => {
        return {
            firstLine: recipe.nameFirstLine,
            secondLine: recipe.nameSecondLine,
            url: getSlugBySite(recipe.showIn) || '',
            urlId: slugify(
                `${recipe.nameFirstLine}${
                    recipe.nameSecondLine ? ` ${recipe.nameSecondLine}` : ''
                }`,
                { lower: true }
            ),
            image: recipe.mediaLink,
            background: recipe.mediaLinkBg,
            fontColor: recipe.linkFontColor,
        };
    });
}
