// extracted by mini-css-extract-plugin
export var backgroundAnimation = "square-item-module--backgroundAnimation--a1c88";
export var boldReversed = "square-item-module--bold-reversed--db473";
export var container = "square-item-module--container--a6393";
export var contentLink = "square-item-module--content-link--eadb8";
export var errorBlink = "square-item-module--error-blink--5e34e";
export var imgBox = "square-item-module--img-box--11f10";
export var light = "square-item-module--light--416d2";
export var name = "square-item-module--name--47efd";
export var nameFirstLine = "square-item-module--name-first-line--12043";
export var ratio = "square-item-module--ratio--3d40d";