// extracted by mini-css-extract-plugin
export var backgroundAnimation = "square-list-module--backgroundAnimation--b7933";
export var container = "square-list-module--container--71ab4";
export var content = "square-list-module--content--5fbde";
export var errorBlink = "square-list-module--error-blink--b193c";
export var itemBox = "square-list-module--item-box--139f4";
export var list = "square-list-module--list--906c7";
export var square = "square-list-module--square--72d11";
export var titleHeader = "square-list-module--title-header--2f286";
export var titleSubtitle = "square-list-module--title-subtitle--2a41b";
export var titleWrapper = "square-list-module--title-wrapper--6bbe7";