import React from 'react';
import { graphql } from 'gatsby';

import { layout, introBox, squares } from './links-page.module.scss';
import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ImageDataLike } from '../models/image-data-like.model';
import { IPageContext, PagesContextMap } from '../models/page-context.model';
import { IProductPreview } from '../models/product-preview.model';

import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getSquareItems } from '../utils/get-square-items';
import { getSquareItemsFromRecipes } from '../utils/get-square-items-from-recipes';
import { getNodes } from '../utils/get-nodes';
import { config } from '../config';

import MainLayout from '../layouts/main-layout';
import PageIntro from '../components/organisms/page-intro';
import SquareList, { ISquareListProps } from '../components/organisms/square-list';

// @ts-ignore
const pagesContext: PagesContextMap = config.pagesContext;

const mainLinks = Object.keys(pagesContext)
    .filter((key) => pagesContext[key].showInMainLinks)
    .map((key) => pagesContext[key].slug);

interface ILinksPageProps {
    readonly data: {
        page: IPage | null;
        allSquareImg: IQueryAllResult<ImageDataLike>;
        allSquareBg: IQueryAllResult<ImageDataLike>;
        allStrapiOldRecipe?: IQueryAllResult<IProductPreview>;
    };
    readonly pageContext: IPageContext;
}

const LinksPage: React.FC<ILinksPageProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { page, allSquareBg, allSquareImg, allStrapiOldRecipe } = data;
    const { site } = pageContext;

    const mainLinksImages = getNodes(allSquareImg);
    const mainLinksBg = getNodes(allSquareBg);

    const recipes = allStrapiOldRecipe && getNodes(allStrapiOldRecipe);
    const recipeSquareItems = (recipes && getSquareItemsFromRecipes(recipes)) || [];
    const { introTitle, introContent } = page || {};

    const squareLists: Record<string, ISquareListProps> = {
        [pagesContext.linksMain.site]: {
            items: getSquareItems({
                images: mainLinksImages,
                backgrounds: mainLinksBg,
                links: mainLinks,
                translationKey: 'linksMain.square',
                t,
            }),
            title: t('linksMain.squares.title'),
        },
        [pagesContext.linksDrinksFlavored.site]: {
            items: recipeSquareItems,
        },
        [pagesContext.linksDrinksLemon.site]: {
            items: recipeSquareItems,
        },
        [pagesContext.linksDrinksDessert.site]: {
            items: recipeSquareItems,
        },
        [pagesContext.linksDesserts.site]: {
            items: recipeSquareItems,
        },
        [pagesContext.linksShots.site]: {
            items: recipeSquareItems,
        },
        [pagesContext.linksMulled.site]: {
            items: recipeSquareItems,
        },
    };

    return (
        <MainLayout page={page} className={layout} showRecipesSlider={false}>
            <div className={introBox}>
                <PageIntro title={introTitle} description={introContent} />
            </div>
            {squareLists[site]?.items?.length > 0 && (
                <SquareList
                    title={squareLists[site].title}
                    items={squareLists[site].items || []}
                    className={squares}
                />
            )}
        </MainLayout>
    );
};

export const query = graphql`
    query (
        $language: String!
        $site: String!
        $showInMainLinks: Boolean = true
        $types: [String]
        $showIn: String
    ) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }

        allSquareImg: allFile(
            filter: {
                relativePath: {
                    in: [
                        "square-links-main-01.png"
                        "square-links-main-02.png"
                        "square-links-main-03.png"
                        "square-links-main-04.png"
                        "square-links-main-05.png"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 99)
                    }
                }
            }
        }

        allSquareBg: allFile(
            filter: {
                relativePath: {
                    in: [
                        "square-links-main-bg-01.png"
                        "square-links-main-bg-02.png"
                        "square-links-main-bg-03.png"
                        "square-links-main-bg-04.png"
                        "square-links-main-bg-05.png"
                        "square-links-main-bg-06.png"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 75)
                    }
                }
            }
        }

        allStrapiOldRecipe(
            filter: {
                locale: { eq: $language }
                showIn: { eq: $showIn }
                type: { in: $types }
                isDummyContent: { eq: false }
            }
            sort: { fields: sequence, order: ASC }
        ) @include(if: $showInMainLinks) {
            edges {
                node {
                    ...oldRecipeFields
                }
            }
        }
    }
`;

export default LinksPage;
