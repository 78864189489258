import React from "react";
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { getImage } from "gatsby-plugin-image";

import { container, contentLink, imgBox, ratio, name, light, nameFirstLine, boldReversed } from './square-item.module.scss';
import { ISquareItem } from "../../models/square-item.model";

import RatioImage from "../atoms/ratio-image";

interface ISquareItemProps {
    className?: string;
    NameTag?: React.ElementType,
    item: ISquareItem;
    isBoldReversed?: boolean;
    imgBig?:boolean;
}

const SquareItem: React.FC<ISquareItemProps> = ({
className = '',
NameTag = 'h3',
item,
isBoldReversed,
}) => {
    const { firstLine, secondLine, image, url, urlId, fontColor } = item;
    return (
        <div className={`${className} ${container} ${isBoldReversed ? boldReversed : ''}`}>
            <Link to={url} urlId={urlId} className={contentLink}>
                <RatioImage
                    image={image && getImage(image.localFile)}
                    className={imgBox}
                    ratioClass={ratio}
                    objectFit="contain"
                />
                <NameTag className={`${name} ${getColorClass(fontColor)}`}>
                    <span className={nameFirstLine}>
                        {firstLine}
                    </span>
                    <span>
                        {secondLine}
                    </span>
                </NameTag>
            </Link>
        </div>
    );
};

function getColorClass(fontColor: ISquareItem['fontColor']) {
    if (fontColor === 'light') return light;
    return '';
}

export default SquareItem;