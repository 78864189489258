import { TFunction } from "react-i18next";

import { ISquareItem } from "../models/square-item.model";
import { ImageDataLike } from "../models/image-data-like.model";

interface IGetSquareItemsConfig {
    images: ImageDataLike[];
    links: string[];
    translationKey: string;
    t: TFunction<string | string[]>;
}

export function getSquareItems(
    {
        images,
        links,
        translationKey,
        t,
    }: IGetSquareItemsConfig
): ISquareItem[] {
    return links.map((link, index) => {
        return {
            firstLine: t(`${translationKey}.firstLine.${index}`),
            secondLine: t(`${translationKey}.secondLine.${index}`),
            image: {
                localFile: images[index],
                caption: '',
                url: '',
                alternativeText: '',
            },
            url: link || '',
        }
    })
}